import React from "react";
import { MyDocumentTicket } from "../PDF/DePagos/DocumentTicket";
import { MyDocumentA4 } from "../PDF/DePagos/DocumentA4";
import NotaA4 from "../PDF/DeNotaCredito/NotaA4";
import FileA4 from "../PDF/DeBilletera/fileA4";
import VentaA4 from "../PDF/DeVentas/VentaA4";
import RemitoA4 from "../PDF/DeRemito/RemitoA4";
import FileTicket from "../PDF/DeBilletera/fileTicket";
import NotaTicket from "../PDF/DeNotaCredito/NotaTicket";
import VentaTicket from "../PDF/DeVentas/VentaTicket";
import RemitoTicket from "../PDF/DeRemito/RemitoTicket";
import PresupuestoA4 from "../PDF/DePresupuesto/PresupuestoA4";
import PagosDeCompras from "../PDF/Components/DeProveedores/PagosDeCompras";
import PresupuestoTicket from "../PDF/DePresupuesto/PresupuestoTicket";
import CuentaCorrienteCliente from "../PDF/DeClientes/CuentaCorrienteCliente";
import MovimientosCCProveedores from "../PDF/DeProveedores/MovimientosCCProveedores";
import { RetencionPdf } from "../PDF/DeRetencion/RetencionPdf";
import ReciboA4 from "../PDF/DeRecibos/ReciboA4";
import ReciboTicket from "../PDF/DeRecibos/ReciboTicket";
import RotuloEnvioVentaA4 from "../PDF/DeVentas/RotuloEnvio/RotuloEnvioVentaA4";

export default function validationComprobantes(
  tipo,
  data,
  configGeneral,
  remito,
  isFCEMA,
  sucursal,
) {
  if (tipo === "reciboA4" || tipo === "reciboA4Ahorro") {
    return <ReciboA4 data={data} tipo={tipo} config={configGeneral} />;
  } else if (tipo === "reciboTicket") {
    return <ReciboTicket data={data} tipo={tipo} config={configGeneral} />;
  } else if (tipo === "ventaA4" || tipo === "ventaA4Ahorro") {
    return (
      <VentaA4
        data={data}
        tipo={tipo}
        config={configGeneral}
        remito={remito}
        isFCEMA={isFCEMA}
      />
    );
  } else if (tipo === "ventaTicket") {
    return <VentaTicket data={data} config={configGeneral} remito={remito} />;
  } else if (tipo === "presupuestoA4" || tipo === "presupuestoA4Ahorro") {
    return <PresupuestoA4 data={data} tipo={tipo} config={configGeneral} />;
  } else if (tipo === "presupuestoTicket") {
    return <PresupuestoTicket data={data} config={configGeneral} />;
  } else if (tipo === "cuentaCorrienteCliente") {
    return <CuentaCorrienteCliente data={data} tipo={tipo} />;
  } else if (tipo === "pagoCompras") {
    return (
      <PagosDeCompras
        data={data}
        tipo={tipo}
        config={configGeneral}
        sucursal={sucursal}
      />
    );
  } else if (tipo === "billeteraA4" || tipo === "billeteraA4Ahorro") {
    return <FileA4 data={data[0]} tipo={tipo} config={configGeneral} />;
  } else if (tipo === "billeteraTicket") {
    return <FileTicket data={data[0]} tipo={tipo} />;
  } else if (tipo === "ticket" || tipo === "ticketPagoDeuda") {
    return <MyDocumentTicket data={data} />;
  } else if (tipo === "A4" || tipo === "A4PagoDeuda" || tipo === "A4Ahorro") {
    return <MyDocumentA4 data={data} tipo={tipo} config={configGeneral} />;
  } else if (tipo === "notacreditoA4" || tipo === "ncA4Ahorro") {
    return (
      <NotaA4
        data={data}
        tipo={tipo}
        config={configGeneral}
        remito={remito}
        isFCEMA={isFCEMA}
      />
    );
  } else if (tipo === "notacreditoTicket") {
    return (
      <NotaTicket
        data={data}
        config={configGeneral}
        remito={remito}
        isFCEMA={isFCEMA}
      />
    );
  } else if (tipo === "movBilleteraProveedores") {
    return <MovimientosCCProveedores data={data} tipo={tipo} />;
  } else if (tipo === "remitoA4" || tipo === "remitoA4Ahorro") {
    return <RemitoA4 data={data} tipo={tipo} config={configGeneral} />;
  } else if (tipo === "remitoTicket") {
    return <RemitoTicket data={data} config={configGeneral} />;
  } else if (tipo === "retencion") {
    return <RetencionPdf data={data} config={configGeneral} />;
  } else if (tipo === "rotuloEnvioA4") {
    return <RotuloEnvioVentaA4 data={data} config={configGeneral} />;
  }
}
