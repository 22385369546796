import request from "../../requests/request";
import { getTicket } from "../../requests/urls";

export function validarCaracteres(numCarac) {
  if (numCarac > 140 && numCarac < 150) {
    return { color: "#ffb400" };
  } else if (numCarac === 150) {
    return { color: "#c4183c " };
  } else {
    return { color: "#3d5170 " };
  }
}

export const getVenta = (idVenta) => {
  try {
    return request({
      method: "GET",
      url: getTicket(idVenta),
    });
  } catch (e) {
    console.error(e);
  }
};

export const superaMontoAFIP = (
  cliente,
  tipoComprobante,
  montos,
  montoMaximo,
) => {
  let err = false;
  if (
    !cliente &&
    tipoComprobante &&
    tipoComprobante.idTipoFactura !== null &&
    montos.montoTotal >= montoMaximo
  ) {
    err = true;
  }
  return err;
};

/**
 * Retorna la suma de los subtotales de los detalles
 * @param {Array} detalles
 * @returns Number
 */
export const calcularSubtotalDetalles = (detalles) => {
  const total = detalles.reduce((acc, item) => item.subtotal + acc, 0);
  return Number(total);
};

/**
 * Retorna el monto sin Iva. Se asume que le monto pasado por parametro
 * contiene el 21%
 * @param {Number} subtotal
 * @returns Number (monto total sin IVA)
 */
export const calcularSubtotalSinIva = (monto) => {
  return Number(Number(monto) / 1.21);
};

/**
 * Calcula el descuento y retorna el monto correspondiente
 * @param {Number} monto
 * @param {Number} descuento Ej: 0.2 0.3 0.4 0.63
 * @returns Number - Monto de descuento
 */
export const calcularMontoDescuento = (monto, descuento) => {
  return Number(Number(monto) * Number(descuento));
};

/**
 * Calcula el interes y retorna el monto correspondiente
 * @param {Number} monto
 * @param {Number} interes Ej: 0.2 0.3 0.4 0.63
 * @returns Number - Monto de insteres
 */
export const calcularMontoInteres = (monto, interes) => {
  return Number(Number(monto) * Number(interes));
};

/**
 *
 * @param {Number} monto monto sin iva sobre el cual aplicar la alicuota
 * @param {NUmber} alicuota Ej: 0.3 0.2 0.05
 * @returns monto del impuesto
 */
export const calcularMontoImpuesto = (monto, alicuota) => {
  return Number(Number(monto) * Number(alicuota));
};

/**
 * Calcula el total de todos los tributos sobre
 * el monto sin iva luego de aplicar el descuento y el interes
 * @param {Number} monto monto sin iva con descuento e intereses aplicados
 * @param {Array} impuestos que se aplican
 */
export const calcularTotalImpuestos = (monto, impuestos) => {
  const totalImpuestos = impuestos.reduce(
    (acc, imp) => calcularMontoImpuesto(monto, imp.alicuota) + acc,
    0,
  );

  return Number(totalImpuestos);
};

export const calcularIVA = (monto) => {
  return Number(monto * 0.21);
};

export const calularMontosEnMediosPago = (
  mediosPago,
  impuestos,
  aplicaIva = false,
  aplicanImpuestos = false,
  esModalMutipleMedioPago = false,
  idOrden,
) => {
  let pagos = mediosPago.map((p) => {
    let montoSinIVA = 0;
    let montoInteres = 0;
    let montoIva = 0;
    let porcentajeImpuestos = 0;

    if (aplicaIva && aplicanImpuestos) {
      porcentajeImpuestos =
        0.21 +
        impuestos
          .filter((imp) => imp.aplica && imp.seleccionado)
          .reduce((acc, imp) => imp.alicuota + acc, 0);
    } else if (aplicaIva && !aplicanImpuestos) {
      porcentajeImpuestos = 0.21;
    }

    if (p.tipo === "Tarjeta" && !esModalMutipleMedioPago) {
      let montoSinInteres = Number(
        (p.monto / (1 + Number(p.coeficiente.porcentaje))).toFixed(2),
      );
      if (idOrden) {
        montoInteres = montoSinInteres * Number(0.0);
      } else {
        montoInteres = montoSinInteres * Number(p.coeficiente.porcentaje);
      }
    }
    let monto = p.monto - montoInteres;

    if (!aplicaIva) {
      montoSinIVA = monto;
    } else {
      if (esModalMutipleMedioPago) {
        montoSinIVA = Number(Number(monto) / (1 + porcentajeImpuestos));
      } else {
        montoSinIVA = Number(Number(monto) / (1 + 0.21));
      }
    }

    if (p.tipo === "Tarjeta") {
      if (idOrden) {
        montoInteres = 0;
      } else {
        montoInteres = Number(montoSinIVA * Number(p.coeficiente.porcentaje));
      }
    } else {
      montoInteres = 0;
    }
    montoSinIVA = Number(montoSinIVA + montoInteres);
    montoIva = Number(aplicaIva ? montoSinIVA * 0.21 : 0);

    let impuestosAplicados = impuestos.map((imp) => ({
      ...imp,
      monto: aplicanImpuestos ? Number(montoSinIVA * imp.alicuota) : 0,
    }));

    let totalImpuestos = Number(
      impuestosAplicados.reduce((acc, imp) => Number(imp.monto) + acc, 0),
    );

    let totalConIvaEImpuestos = montoSinIVA + montoIva + totalImpuestos;

    let montoInteresConImpuestosEIva = montoInteres * (1 + porcentajeImpuestos);

    return {
      ...p,
      montoSinIva: montoSinIVA,
      totalImpuestos,
      montoInteres,
      montoIva,
      impuestosAplicados,
      montoInteresConImpuestosEIva,
      totalConIvaEImpuestos: Number(totalConIvaEImpuestos),
      aplicaIva,
      aplicanImpuestos,
    };
  });
  return pagos;
};

export const calcularTotalesPorImpuesto = (
  impuestosAplican = [],
  montoConDescuentoEInteresSinIvaEImpuestos,
) => {
  const impuestos = impuestosAplican.map((imp) => {
    let totalImpuesto =
      montoConDescuentoEInteresSinIvaEImpuestos * Number(imp.alicuota);
    return {
      ...imp,
      monto: Number(totalImpuesto.toFixed(2)),
      monto_base: Number(montoConDescuentoEInteresSinIvaEImpuestos.toFixed(2)),
    };
  });
  return impuestos;
};

export const montoTotalConImpuestos = (mediosPagosProcesados) => {
  return mediosPagosProcesados.reduce(
    (acc, mp) => mp.totalConIvaEImpuestos + acc,
    0,
  );
};

export const calculoMontosVentas = (
  presupuesto,
  esModalMutipleMedioPago = false,
  idOrden,
) => {
  const aplicaIva =
    presupuesto.tipoComprobante &&
    (presupuesto.tipoComprobante.nombre === "A" ||
      presupuesto.tipoComprobante.nombre === "FCEM A" ||
      presupuesto.tipoComprobante.nombre === "M");

  // Traer lo impuestos que tiene le presupuesto
  // Traer los del cliente,
  // Los impuestos que se tienen que aplicar son aquellos que el cliente tiene activos

  const aplicanImpuestos = Boolean(
    presupuesto.cliente && presupuesto.cliente.impuestos.length > 0,
  );

  const subtotalArticulos = Number(
    calcularSubtotalDetalles(presupuesto.detalles),
  );

  const subtotal = aplicaIva
    ? Number(
        calcularSubtotalSinIva(calcularSubtotalDetalles(presupuesto.detalles)),
      )
    : Number(calcularSubtotalDetalles(presupuesto.detalles));

  let impuestosAplicados =
    aplicanImpuestos && aplicaIva
      ? presupuesto.cliente.impuestos.filter(
          (imp) => subtotal >= Number(imp.minimo_no_imponible),
        )
      : [];

  impuestosAplicados = impuestosAplicados.map((imp) => ({
    ...imp,
    alicuota: Number(imp.alicuota) / 100,
    minimo_no_imponible: Number(imp.minimo_no_imponible),
  }));

  const mediosPagoProcesadosConMontosEImpuestos = calularMontosEnMediosPago(
    presupuesto.medioPago,
    impuestosAplicados,
    aplicaIva,
    aplicanImpuestos,
    esModalMutipleMedioPago,
    idOrden,
  );

  const montoDescuento = calcularMontoDescuento(
    subtotal,
    (presupuesto.descuento && presupuesto.descuento.porcentaje) || 0,
  );

  const subtotalConDescuentoSinImpuestos = Number(subtotal - montoDescuento);

  const montoInteres = Number(
    mediosPagoProcesadosConMontosEImpuestos.reduce(
      (acc, mp) => mp.montoInteres + acc,
      0,
    ),
  );

  const montoConDescuentoEInteresSinIvaEImpuestos = Number(
    subtotalConDescuentoSinImpuestos + montoInteres,
  );

  const montoIVA = aplicaIva
    ? calcularIVA(montoConDescuentoEInteresSinIvaEImpuestos)
    : 0;

  const totalConIvaSinImpuestos = aplicaIva
    ? Number(montoConDescuentoEInteresSinIvaEImpuestos * 1.21)
    : montoConDescuentoEInteresSinIvaEImpuestos;

  const totalesPorImpuesto = aplicanImpuestos
    ? calcularTotalesPorImpuesto(
        impuestosAplicados,
        montoConDescuentoEInteresSinIvaEImpuestos,
      )
    : [];

  const montoTotalEnImpuestos = Number(
    totalesPorImpuesto.reduce((acc, imp) => {
      return Number(imp.monto) + acc;
    }, 0),
  );

  const montoTotalConIvaEImpuestos = Number(
    montoTotalEnImpuestos + totalConIvaSinImpuestos,
  );

  const montoTotalImpuestosDesdeSubtotal =
    subtotalConDescuentoSinImpuestos *
    impuestosAplicados.reduce((acc, imp) => imp.alicuota + acc, 0);

  const montoConIvaEImpuestosSinInteres =
    (aplicaIva
      ? Number(subtotalConDescuentoSinImpuestos * 1.21)
      : subtotalConDescuentoSinImpuestos) + montoTotalImpuestosDesdeSubtotal;

  const subtotalConDescuentoConIvaConImpuestosSinIntereses =
    (aplicaIva
      ? Number(subtotalConDescuentoSinImpuestos * 1.21)
      : subtotalConDescuentoSinImpuestos) + montoTotalImpuestosDesdeSubtotal;

  return {
    aplicaIva,
    aplicanImpuestos,
    subtotalArticulos: Number(subtotalArticulos.toFixed(2)),
    subtotal: Number(subtotal.toFixed(2)),
    montoDescuento: Number(montoDescuento.toFixed(2)),
    subtotalConDescuentoSinImpuestos: Number(
      subtotalConDescuentoSinImpuestos.toFixed(2),
    ),
    montoConDescuentoEInteresSinIvaEImpuestos: Number(
      montoConDescuentoEInteresSinIvaEImpuestos.toFixed(2),
    ),
    totalConIvaSinImpuestos: Number(totalConIvaSinImpuestos.toFixed(2)),
    montoInteres: Number(montoInteres.toFixed(2)),
    montoIVA: Number(montoIVA.toFixed(2)),
    mediosPagoProcesadosConMontosEImpuestos:
      mediosPagoProcesadosConMontosEImpuestos.map((mp) => ({
        ...mp,
        montoIva: Number(mp.montoIva.toFixed(2)),
        montoInteres: Number(mp.montoInteres.toFixed(2)),
        totalConIvaEImpuestos: Number(mp.totalConIvaEImpuestos.toFixed(2)),
      })),
    montoTotalConIvaEImpuestos: Number(montoTotalConIvaEImpuestos.toFixed(2)),
    totalesPorImpuesto: totalesPorImpuesto.map((imp) => ({
      ...imp,
      monto: Number(imp.monto.toFixed(2)),
    })),
    montoTotalEnImpuestos: Number(montoTotalEnImpuestos.toFixed(2)),
    montoConIvaEImpuestosSinInteres: Number(
      montoConIvaEImpuestosSinInteres.toFixed(2),
    ),
    subtotalConDescuentoConIvaConImpuestosSinIntereses: Number(
      subtotalConDescuentoConIvaConImpuestosSinIntereses.toFixed(2),
    ),
  };
};
