import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";

export default function DataClienteA4CIRotulo({ data }) {
  return (
    <View
      style={{
        minHeight: data.venta.cliente ? 100 : 75,
        maxHeight: data.venta.cliente ? 100 : 75,
      }}
    >
      <View style={styles.containerRow}>
        <View style={{ flexDirection: "column", width: 185 }}>
          <View style={{ flexDirection: "row" }}>
            <View style={(styles.containerColumn, { width: 185 })}>
              <Text style={styles.text2Title}>{`Sr.(es):`}</Text>
              <Text style={styles.text2}>
                {data.venta.cliente
                  ? data.venta.cliente.razonSocial
                  : " Consumidor Final"}
              </Text>
            </View>
          </View>

          {data.venta.cliente ? (
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 185 })}>
                <Text style={styles.text2Title}>{`Correo electrónico:`}</Text>
                <Text style={styles.text2}>
                  {data.venta.cliente
                    ? data.venta.cliente.correoElectronico
                    : "---"}
                </Text>
              </View>
            </View>
          ) : null}

          <View style={{ flexDirection: "row" }}>
            <View style={(styles.containerColumn, { width: 185 })}>
              <Text style={styles.text2Title}>{`Transporte:`}</Text>
              <Text style={styles.text2}>
                {data.venta.transporte_venta
                  ? data.venta.transporte_venta.nombre
                  : "---"}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "column", width: 185 }}>
          {data.venta.cliente ? (
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 185 })}>
                <Text style={styles.text2Title}>{`CUIT:`}</Text>
                <Text style={styles.text2}>
                  {data.venta.cliente && data.venta.cliente.CUIT
                    ? data.venta.cliente.CUIT
                    : "---"}
                </Text>
              </View>
            </View>
          ) : null}

          {data.venta.responsable_venta ? (
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 185 })}>
                <Text style={styles.text2Title}>{`Responsable:`}</Text>
                <Text style={styles.text2}>
                  {data.venta.responsable_venta &&
                  data.venta.responsable_venta.nombre
                    ? data.venta.responsable_venta.nombre
                    : "---"}
                </Text>
              </View>
            </View>
          ) : null}

          <View style={{ flexDirection: "row" }}>
            <View style={(styles.containerColumn, { width: 185 })}>
              <Text style={styles.text2Title}>{`Observación:`}</Text>
              <Text style={styles.text2}>
                {data.venta.descripcion || "Ninguna"}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "column", width: 185 }}>
          {data.venta.cliente ? (
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>{`Domicilio:`}</Text>
                <Text style={styles.text2}>
                  {data.venta.cliente && data.venta.cliente.domicilio
                    ? data.venta.cliente.domicilio
                    : "---"}
                </Text>
              </View>
            </View>
          ) : null}

          <View style={{ flexDirection: "row" }}>
            <View style={(styles.containerColumn, { width: 150 })}>
              <Text style={styles.text2Title}>{`Tipo de venta:`}</Text>
              <Text style={styles.text2}>
                {data.venta.tipo_venta
                  ? data.venta.tipo_venta.descripcion
                  : "---"}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
