import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Backdrop,
  Collapse,
  Checkbox,
} from "@material-ui/core";
import { getFilters as getFilterBuscadorArticulo } from "../../Proveedores/Catalogo/utils";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import {
  descargarExcelVentas,
  getInformeMisVentas,
  sendMailVenta,
  getVentasInFactAgrupada,
  descargarExcelVentasDeVentas,
} from "../../../requests/urls";
import {
  formatVentas,
  getData,
  getFilters,
  obtenerInitialTipoCalculoTotales,
  tieneChequesUtilizados,
} from "./utils";
import { getFormatedNow, lastMonday } from "../../../utils/dates";
import { useDispatch, useSelector } from "react-redux";
import { ModalFechaVencimiento } from "./ModalFechaVencimiento/ModalFechaVencimiento";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import { pdfNotaDeCredito } from "../NotaDeCredito/request";
import { useDebounce } from "../../../customHooks/useDebounce";
import { initialPdf } from "../NotaDeCredito/utils";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../components/ait-reusable/HandlePdf/handlePdf";
import { getPdfBlob } from "../../../components/ait-reusable/ModalWhatsApp/uploadPdf";
import { useHistory } from "react-router-dom";
import { isVendedor } from "../../../data/perfil";
import { makeStyles } from "@material-ui/core/styles";
import { Showmodal } from "../../../components/ait-reusable/PDF/Components/PreviewPdf";
import { getVenta } from "../../Venta/utils";
import axios from "axios";
import Stats from "./Stats";
import request from "../../../requests/request";
import Filtros from "./Filtros";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";
import ButtonFilter from "../../../components/ait-reusable/Button/ButtonFilter";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import ModalDetalle from "./ModalDetalle";
import ModalValidate from "./ModalValidate";
import ModalWhatsApp from "../../../components/ait-reusable/ModalWhatsApp/ModalWhatsApp";
import ModalSendEmail from "../../../components/ait-reusable/ModalSendEmail/ModalSendEmail";
import Config_general from "../utils";
import useSearchClients from "../../../customHooks/useSearchClients";
import ModalNotaCredito from "../../../components/ait-reusable/ModalNotaCredito";
import MessageTooltipTR from "../../../components/ait-reusable/MessageTooltipTR";
import FilterHeaderTable from "./FilterHeaderTable";
import ModalSeleccionarNc from "./ModalSeleccionarNc/ModalSeleccionarNc";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import useClienteDescuentoPlazo from "../../../customHooks/useClienteDescuentoPlazo";
import ModalImprimirComprobante from "./ModalImprimirComprobante/ModalImprimirComprobante";
import ModalComprobanteAFactura from "./ModalComprobanteAFactura/ModalComprobanteAFactura";
import ModalVentasValidateCAEError from "./ModalVentasValidateCAEError";
import ModalFacturarClienteTercero from "./ModalFacturarClienteTercero";
import useArticlesToPDF from "../../../customHooks/useArticlesToPDF";
import { ModalContentAnulacion } from "./ModalContentAnulacion";
import { useSearchTipoVenta } from "../../../customHooks/useSearchTipoVenta";
import { formatDataObsNCToBack } from "../../../components/ait-reusable/ObservacionNotaCreditoVenta/utils";
import useImportesFacturacion from "../../../customHooks/useImportesFacturacion";
import { useFiltroStock } from "../../../customHooks/useFiltroStock";
import useArticulosSucursal from "../../../customHooks/useGetArticulosSucursal";
import ModalFacturacionAgrupada from "./ModalFacturacionAgrupada";
import ModalValidacionCAE from "../../../components/ait-reusable/ModalesErrorAFIP/ModalValidacionCAE";
import ModalValidacionNotasCredito from "../../../components/ait-reusable/ModalesErrorAFIP/ModalValidacionNotasCredito";
import ModalValidacionGeneracionNC from "../../../components/ait-reusable/ModalesErrorAFIP/ModalValidacionGeneracionNC";
import { CacheRequestContext } from "../../../context/CacheRequestContext";
import ModalAnulacionFactAgrupada from "../../../components/ait-reusable/ModalAnulacionFactAgrupada";
import useSelectionFactAgrupada from "./useSelectionFactAgrupada";
import ComponenteProtegido from "../../../components/ait-reusable/ComponenteProtegido/ComponenteProtegido";
import useImprimirRotuloEnvio from "./useImprimirRotuloEnvio";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Ventas = () => {
  const classes = useStyles();
  const history = useHistory();
  // const cacheContext = useContext(CacheRequestContext);

  const { perfil, aplica_impuesto } = useSelector(
    (state) => state.loginReducer,
  );
  const { setNewClient } = useClienteDescuentoPlazo();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { useClient, useQuery } = useSearchClients();
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const { useTipoVenta, useQueryTipoVenta } = useSearchTipoVenta();
  const [venta, setVenta] = useState(null);
  const [ventas, setVentas] = useState([]);
  const [period, setPeriod] = useState("today");
  const [urlPdf, setUrlPdf] = useState(null);
  const [search, setSearch] = useState("");
  const [totales, setTotales] = useState({});
  const [preview, setPreview] = useState(false);
  const [datapdf, setDatapdf] = useState(null);
  const [tipoPdf, setTipoPdf] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFcemA, setIsFcemA] = useState(false);
  const [isRemito, setRemito] = useState(false);
  const [selection, setSelection] = useState([]);
  const [modalPdf, setModalPdf] = useState(initialPdf);
  const [dataEmail, setDataEmail] = useState(null);
  // const [recaudados, setRecaudados] = useState({});
  const [cantConCAE, setCantConCAE] = useState(0);
  const [mediosPago, setMediosPago] = useState([]);
  const [volverModal, setVolverModal] = useState(false);
  const [modalAnular, setModalAnular] = useState(false);
  const [listfilters, setListfilters] = useState([]);
  const [ventaAnular, setVentaAnular] = useState(null);
  const [ventasError, setVentasError] = useState([]);
  const [valueFcemA, setValueFcemA] = useState("N");
  const [conComision, setConComision] = useState(null);
  const [idMedioPago, setIdMedioPago] = useState(null);
  const [tipoCliente, setTipoCliente] = useState(null);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [pendienteCae, setPendienteCae] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [modalDetalle, setModalDetalle] = useState(false);

  const [modalErrorCae, setModalErrorCae] = useState(false);
  const [ventaSelected, setVentaSelected] = useState(null);
  const [openModalEmail, setOpenModalEmail] = useState(false);
  const [dataPdfFetched, setDataPdfFetched] = useState(null);
  const [ventasSelected, setVentasSelected] = useState([]);
  const [conNotaCredito, setConNotaCredito] = useState("all");
  const [modalGenerarCAE, setModalGenerarCAE] = useState(false);
  const [modalSeleccionar, setModalSeleccionar] = useState(false);
  const [tiposComprobante, setTiposComprobante] = useState([]);
  const [modalComprobante, setModalComprobante] = useState(false);
  const [ventaComprobante, setVentaComprobante] = useState({});
  const [openModalWhatsapp, setOpenModalWhatsapp] = useState(false);
  const [openBackdropAnular, setOpenBackdropAnular] = useState(false);
  const [disabledAnularVenta, setDisabledAnularVenta] = useState(false);
  const [modalFechaVencimiento, setModalFechaVencimiento] = useState(false);
  const [modalComprobanteAFactura, setModalComprobanteAFactura] =
    useState(false);
  const [loadingTotales, setLoadingTotales] = useState(false);
  const [openModalFacturaAgrupada, setOpenModalFacturaAgrupada] =
    useState(false);
  const [dates, setDates] = useState({
    fromDate: getFormatedNow(),
    toDate: getFormatedNow(),
  });
  const [isOpenmodalFacturarATerceros, setOpenModalFacturarATerceros] =
    useState(false);
  const [modalDetalleAnularComprobante, setModalDetalleAnularComprobante] =
    useState(false);
  const [filters, setFilters] = useState({
    medioPago: null,
    tarjetaSelect: null,
    tipoComprobante: null,
    clienteSelected: null,
    tipoVenta: null,
    provincia: null,
    transporte: null,
  });

  // Hook para calculo el tipo de calculo de los totales
  // en caso de incluir mas adelante para el tabla, agregar al resto de los filtros
  const [
    incluirTodasLasDevolucionesDePeriodo,
    setIncluirTodasLasDevolucionesDePeriodo,
  ] = useState(configGeneral.incluir_todas_devoluciones_de_periodo);

  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [article, setArticle] = useState(null);
  const { dataArticulos } = useArticulosSucursal({
    listfilters,
    useMeli: false,
    proveedorSelect: "0",
  });
  const {
    loading: loadingArticulos,
    articulos,
    getArticulosSucursal,
    filters: filtersArticulos,
  } = dataArticulos;
  const {
    checkedArt,
    soloStock,
    sinStock,
    tieneMeli,
    filter,
    busquedaArticulo,
    debouncedSearchTerm,
    setCheckedArt,
    setSoloStock,
    setSinStock,
    setTieneMeli,
    setFilter,
    setBusqueda,
  } = filtersArticulos;

  const {
    handleChangeArt,
    handleChangeTodosMisArt,
    handleChangeSoloStock,
    handleChangeSinStock,
    handleChangeConVinculacionMeli,
  } = useFiltroStock(setCheckedArt, setSoloStock, setSinStock, setTieneMeli);

  const { montoMaximo } = useImportesFacturacion();
  const [dataErrorAFIP, setDataErrorAFIP] = useState({
    devoluciones: [],
    nroFactura: null,
    type: null,
    open: false,
    venta: null,
  });

  const [observaciones, setObservaciones] = useState([{ id: null, value: "" }]); //Siempre debe tener ese primer elemento ya que es donde se guarda la obs escrita
  const debouncedSearchDescripcion = useDebounce(search, 500);
  const { articlesPDF } = useArticlesToPDF();
  const [ventasAgrupadas, setVentasAgrupadas] = useState([]);
  const { useSelection } = useSelectionFactAgrupada(
    selection,
    ventas,
    setSelection,
  );
  const { handleImprimirRotulo } = useImprimirRotuloEnvio(
    configGeneral,
    setOpenBackDrop,
    getBlobPdf,
    imprimirPdf,
    setPreview,
    setTipoPdf,
    setDatapdf,
  );

  useEffect(() => {
    getArticulosSucursal();
  }, [debouncedSearchTerm]);

  const handleClose = () => {
    setOpenBackDrop(false);
  };

  const haveArticles = () => {
    /**
     * La función verifica si un artículo tiene una propiedad "repuesto_sucursal" y devuelve su valor
     * "idRepuestoSucursal", en caso contrario devuelve el valor "idRepuestoProveedor", o una cadena
     * vacía si no existe ninguna propiedad.
     * :return: La función `haveArticles` devuelve la propiedad `idRepuestoSucursal` del objeto
     * `repuesto_sucursal` si existe en el objeto `article`. Si el objeto `repuesto_sucursal` no existe
     * en el objeto `article` devuelve la propiedad `idRepuestoProveedor`. Si ni el objeto
     * `repuesto_sucursal` ni el `idRep
     */
    if (article && article.repuesto_sucursal) {
      return article.repuesto_sucursal.idRepuestoSucursal;
    } else if (article && !article.repuesto_sucursal) {
      return article.idRepuestoProveedor;
    } else {
      return "";
    }
  };

  const getVentas = async (checked, paginated) => {
    // #diabolico
    const isChecked = checked ? (checked ? 1 : 0) : 0;

    setLoading(true);
    setLoadingTotales(true);
    getData(
      dates,
      filters.tipoComprobante || "",
      filters.medioPago || "",
      useClient.client ? useClient.client.idClienteDeSucursal : "",
      conNotaCredito === "all" ? "" : conNotaCredito,
      useEmpleado.empleado ? useEmpleado.empleado.idEmpleado : "",
      debouncedSearchDescripcion,
      filters.tarjetaSelect || "",
      haveArticles(),
      useTipoVenta.tipoDeVenta ? useTipoVenta.tipoDeVenta.id : "",
      tipoCliente ? tipoCliente : null,
      isChecked,
      incluirTodasLasDevolucionesDePeriodo,
      filters.provincia ? filters.provincia.id : null,
      filters.transporte ? filters.transporte.id : null,
    ).then((response) => {
      setTotales(response.totalesMedioPago.data.data);
      // setRecaudados(response.totalesRecaudados.data.data);
      setLoadingTotales(false);
    });
    await getCanVentasConCAE();
    try {
      const response = await request({
        method: "GET",
        url: getInformeMisVentas,
        params: {
          inicio: dates.fromDate,
          fin: dates.toDate,
          page: pagination.page,
          medioPago: filters.medioPago,
          tipoComprobante: filters.tipoComprobante,
          venta: debouncedSearchDescripcion,
          cliente: useClient.client ? useClient.client.idClienteDeSucursal : "",
          responsable: useEmpleado.empleado
            ? useEmpleado.empleado.idEmpleado
            : "",
          pendientesCae: isChecked,
          paginated: paginated !== undefined ? paginated : 1,
          notaCredito: conNotaCredito === "all" ? "" : conNotaCredito,
          id_tarjeta: filters.tarjetaSelect || "",
          articulo: article ? article.repuesto_sucursal.idRepuestoSucursal : "",
          tipo_venta: useTipoVenta.tipoDeVenta
            ? useTipoVenta.tipoDeVenta.id
            : "",
          tipo_cliente: tipoCliente ? tipoCliente : null,
          provincia: filters.provincia ? filters.provincia.id : null,
          transporte: filters.transporte ? filters.transporte.id : null,
        },
      });
      await handleResponseVentas(response);
    } catch (error) {
      console.error(error);
      clearState();
    }
  };
  const getCanVentasConCAE = async () => {
    try {
      const response = await request({
        method: "GET",
        url: "/api/venta/count-pendientes-cae/",
        params: {
          inicio: dates.fromDate,
          fin: dates.toDate,
          page: pagination.page,
          venta: debouncedSearchDescripcion,
          cliente: useClient.client ? useClient.client.idClienteDeSucursal : "",
          responsable: useEmpleado.empleado
            ? useEmpleado.empleado.idEmpleado
            : "",
          notaCredito: conNotaCredito === "all" ? "" : conNotaCredito,
          articulo: article ? article.repuesto_sucursal.idRepuestoSucursal : "",
          tipo_venta: useTipoVenta.tipoDeVenta
            ? useTipoVenta.tipoDeVenta.id
            : "",
          provincia: filters.provincia ? filters.provincia.id : null,
          transporte: filters.transporte ? filters.transporte.id : null,
        },
      });
      setCantConCAE(response.status === 200 ? response.data : 0);
    } catch (error) {
      setCantConCAE(0);
      console.error(error);
      clearState();
    }
  };

  const handleResponseVentas = (response) => {
    if (response.status === 200) {
      let data = response.data.data;
      setTimeout(() => {
        setLoading(false);
      }, 150);
      setVentas(data.items);
      setPagination({
        ...pagination,
        num_items: data.num_items,
        next_page: data.next_page,
      });
    } else {
      clearState();
    }
  };

  /** Funcion encargada de  realizar PUT para la anular la venta,
   * una vez anulada, se setea la tabla del detalle
   * @param ventaActual es la venta seleccionada que se quiere anular
   */
  const handleAnularVenta = (
    ventaAnular,
    setVenta,
    setModalAnular,
    getVentas,
  ) => {
    setDisabledAnularVenta(true);
    setOpenBackdropAnular(true);
    const token = localStorage.getItem("token");
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .put(`/api/venta/anular/${ventaAnular.id}/`, {
        medio_pago: idMedioPago ? idMedioPago : null,
        anulacion_fcem: valueFcemA,
        observacion: formatDataObsNCToBack(observaciones),
      })
      .then((res) => {
        setVenta(null);
        setDisabledAnularVenta(false);
        setValueFcemA(null);
        setObservaciones([{ id: null, value: "" }]);
        setModalAnular(false);
        getVentas();
        successNotification("La venta se anuló correctamente");
        setOpenBackdropAnular(false);
      })
      .catch((err) => {
        if (Array.isArray(err.response.data)) {
          const errorData = err.response.data.join(" ");
          if (errorData.includes("Boxer fallida")) {
            successNotification(
              "Factura anulada con éxito, pero no se pudo actualizar la misma en Boxer. Comuníquese con soporte.",
              6000,
            );
            setValueFcemA(null);
            setObservaciones([{ id: null, value: "" }]);
            setModalAnular(false);
            getVentas();
          } else if (
            errorData.includes("Error al validar la nota de crédito con ID")
          ) {
            errorNotification(
              "Factura anulada con éxito pero ocurrió un error al validar la nota de crédito con AFIP.",
              4000,
            );
            const expresionRegular = /ID: (\S+)/;

            const resultado = errorData.match(expresionRegular);
            let notaCredito =
              resultado && resultado.length > 1 ? resultado[1] : null;

            setValueFcemA(null);
            setObservaciones([{ id: null, value: "" }]);
            setModalAnular(false);

            setDataErrorAFIP((prev) => ({
              ...prev,
              devoluciones: [notaCredito],
              nroFactura: ventaAnular.nro_factura,
              type: "notaCredito",
              open: true,
            }));
          } else if (errorData.includes("Error 10154:")) {
            errorNotification(
              "Este comprobante no se encuentra rechazado por el comprador.",
            );
          } else if (errorData.includes("Error 10041:")) {
            errorNotification("Comprobante electrónico aún no autorizado.");
          } else {
            errorNotification("Error al anular la venta, por favor reintente");
          }
        } else {
          errorNotification("Error al anular la venta, por favor reintente");
        }
        setOpenBackdropAnular(false);
        setDisabledAnularVenta(false);
      });
  };
  function clearState() {
    setTimeout(() => {
      setLoading(false);
    }, 150);
    setVentas([]);
    setPagination({
      ...pagination,
      page: 1,
      num_items: 0,
      next_page: null,
    });
  }

  const updateVentas = (venta) => {
    getVentas();
  };

  useEffect(() => {
    getListFilters();

    let value = localStorage.getItem("filtroMostrar");

    if (value) {
      switch (Number(value)) {
        case 1:
          handleChangeArt(true);
          break;
        case 2:
          handleChangeTodosMisArt(true);
          break;
        case 3:
          handleChangeSoloStock(true);
          break;
        case 4:
          handleChangeSinStock(true);
          break;
        case 5:
          handleChangeConVinculacionMeli(true);
        default:
          break;
      }
    }
  }, []);

  const getListFilters = () => {
    getFilterBuscadorArticulo().then((res) => {
      setListfilters(res);
      res.length > 0 &&
        setFilter(localStorage.getItem("filtroArticulos") || res[0].id);
    });
  };

  useEffect(() => {
    getVentas();
  }, [
    pagination.page,
    debouncedSearchDescripcion,
    incluirTodasLasDevolucionesDePeriodo,
  ]);

  useEffect(() => {
    // getData(dates).then((response) => {
    //   setTotales(response.totalesMedioPago.data.data);
    //   // setRecaudados(response.totalesRecaudados.data.data);
    // });
    getFilters().then((response) => {
      setMediosPago(response.mediosPago.data.data);
      setTiposComprobante(response.tiposComprobante.data.data);
    });
  }, []);

  function toggleModalDetalle() {
    setModalDetalle(!modalDetalle);
    //El set y el if se ejecutan al mismo tiempo, por lo cual el if valida el valor inicial no el modificado por el setModalDetalle.
    if (modalDetalle) {
      setVentaSelected(null);
    }
  }

  function toggleModalDetalleAnular(venta) {
    notaCredito_o_devolucion(venta);
  }

  const setComprobanteDetalle = (ventaSelected) => {
    setVentaSelected(ventaSelected);
    setModalSeleccionar(false);
    toggleModalDetalle();
  };

  const toggleModalComprobanteAFactura = (venta) => {
    if (selection.some((s) => s.id === venta.id)) {
      quitarSelectionCItoAgrupada(venta);
    }
    setVentaSelected(venta);
    setModalComprobanteAFactura(!modalComprobanteAFactura);
  };

  const toggleModalValidate = (venta) => {
    setVentaSelected(venta);
    setVentasSelected([]);
    setModalGenerarCAE(!modalGenerarCAE);
  };

  const toggleModalValidateMasivo = () => {
    let ventasAvalidar = [];
    ventas.length > 0 && ventas.map((item) => ventasAvalidar.push(item.id));
    setVentasSelected(ventasAvalidar);
    setVentaSelected(null);
    setModalGenerarCAE(!modalGenerarCAE);
  };

  const handleSearch = ({ target }) => {
    const { value } = target;
    setPagination({ ...pagination, page: 1 });
    setSearch(value);
  };

  const handleReCrearVenta = (idVenta) => {
    history.push(`/ventas/re-venta/${idVenta}/`);
  };

  const generarPdfConPrevisualizacion = async (
    tipo,
    id,
    isFCEMA,
    comprobImprimir,
  ) => {
    setOpenBackDrop(true);
    try {
      getVenta(id).then(async (res) => {
        let newData = articlesPDF.getArticlesPDF(
          res.data.data,
          configGeneral,
          tipo,
          isRemito,
          comprobImprimir,
        );

        if (comprobImprimir) {
          newData.comprobanteAImprimir = comprobImprimir;
        }

        if (isFCEMA) {
          setIsFcemA(true);
        }
        setTipoPdf(tipo);
        setDatapdf(newData);
        setOpenBackDrop(false);
        setPreview(true);
        const blobPdf = await getBlobPdf(
          tipo,
          newData,
          configGeneral,
          isRemito,
          isFCEMA,
        );
        imprimirPdf(blobPdf);
      });
    } catch (error) {
      console.error(error);
      setOpenBackDrop(false);
    }
  };

  const generarPdfConNotaCreditoConPrevisualizacion = async (
    tipo,
    id,
    isFCEMA,
  ) => {
    setOpenBackDrop(true);
    try {
      setModalPdf({
        ...modalPdf,
        openBackDrop: true,
      });
      let dataPdf = await pdfNotaDeCredito(id);
      const blobPdf = await getBlobPdf(
        tipo,
        dataPdf,
        configGeneral,
        isRemito,
        isFCEMA,
      );
      if (dataPdf) {
        setModalPdf({
          type: tipo,
          data: dataPdf,
          openBackDrop: false,
          preview: true,
        });

        imprimirPdf(blobPdf);
      }
      setOpenBackDrop(false);
    } catch (error) {
      console.error(error);
      setOpenBackDrop(false);
    }
  };

  const generarPdfConNotaCredito = async (tipo, id, isFCEMA) => {
    setOpenBackDrop(true);
    try {
      const response = await pdfNotaDeCredito(id);
      const blobPdf = await getBlobPdf(
        tipo,
        response,
        configGeneral,
        isRemito,
        isFCEMA,
      );
      imprimirPdf(blobPdf);
    } catch (error) {
      console.error(error);
      errorNotification(
        "Error al imprimir la nota de crédito, intente nuevamente.",
      );
    }
    setOpenBackDrop(false);
  };

  const generarPdf = async (tipo, id, isFCEMA, comprobImprimir) => {
    setOpenBackDrop(true);
    try {
      const response = await getVenta(id);
      let newData = articlesPDF.getArticlesPDF(
        response.data.data,
        configGeneral,
        tipo,
        isRemito,
        comprobImprimir,
      );

      if (comprobImprimir) {
        newData.comprobanteAImprimir = comprobImprimir;
      }

      const blobPdf = await getBlobPdf(
        tipo,
        newData,
        configGeneral,
        isRemito,
        isFCEMA,
      );
      imprimirPdf(blobPdf);
    } catch (error) {
      console.error(error);
      errorNotification("Error al imprimir la venta, intente nuevamente.");
    }
    setOpenBackDrop(false);
  };

  const togglePendienteCAE = async (e) => {
    setPendienteCae(!pendienteCae);
    console.log("Toggle", e.target.checked);
    getVentas(e.target.checked);
  };

  const toggle = async () => {
    setModalPdf({
      type: "",
      data: "",
      openBackDrop: false,
      preview: false,
    });
    await setPreview(false);
    setVolverModal(false);
    setRemito(false);
    setIsFcemA(false);
  };

  const modalErrorDetalle = (ventas) => {
    setVentasError(ventas);
    setModalErrorCae(!modalErrorCae);
  };

  /**es como nota de crédito pero para comprobante interno */
  const generarDevolucion = (venta) => notaCredito_o_devolucion(venta);

  const notaCredito_o_devolucion = (venta) => {
    setConComision(null);
    localStorage.removeItem("conComision");
    if (venta) {
      venta.pagos_cliente.find((x) => {
        if (x.medio_pago === null) {
          setConComision(x);
          localStorage.setItem("conComision", 1);
        }
      });
    }

    if (modalDetalleAnularComprobante) {
      quitarSelectionCItoAgrupada(ventaSelected);
      setVentaSelected(null);
    } else {
      setVentaSelected(venta);
    }

    setModalDetalleAnularComprobante(!modalDetalleAnularComprobante);
  };

  const quitarSelectionCItoAgrupada = (venta) => {
    let copySelection = [...selection];

    copySelection = copySelection.filter((item) => item.id !== venta.id);

    setSelection(copySelection);
  };

  const abrirModalFacturarATerceros = (ventaSelected) => {
    if (selection.some((s) => s.id === ventaSelected.id)) {
      quitarSelectionCItoAgrupada(ventaSelected);
    }
    setVentaSelected(ventaSelected);
    setOpenModalFacturarATerceros(true);
  };

  const cerrarModalFacturarATerceros = (buttonEvent) => {
    setOpenModalFacturarATerceros(false);
    setVentaSelected(null);
    !buttonEvent && getVentas();
  };

  const handleSendMail = (idVenta, cliente) => {
    let correoCliente = cliente ? cliente.email : "";
    setDataEmail({ idVenta, correoCliente });
    setOpenModalEmail(true);
  };

  const sendEmail = async (newCorreoCliente, setLoadingEmail) => {
    setOpenModalEmail(false);
    try {
      const response = await request({
        method: "GET",
        url: sendMailVenta(dataEmail.idVenta),
        params: { mail: newCorreoCliente },
      });
      if (response.status === 201 || response.status === 200) {
        successNotification(
          "Email enviado con éxito. Puede avisar que se revise la bandeja de entrada.",
        );
        setDataEmail(null);
        setLoadingEmail(false);
      }
    } catch (error) {
      console.error(error);
      setDataEmail(null);
      setLoadingEmail(false);
    }
  };

  const handleModalFechaVencimiento = (venta) => {
    setModalFechaVencimiento(true);
    setVenta(venta);
  };

  const handleOpenModalSeleccionar = (venta) => {
    setModalSeleccionar(true);
    setVenta(venta);
  };

  const handleOpenModalComprobante = (venta) => {
    setModalComprobante(true);
    setVentaComprobante(venta);
  };

  const getDataPdf = async (venta) => {
    setOpenBackDrop(true);
    try {
      getVenta(venta.id).then((res) => {
        let newData = articlesPDF.getArticlesPDF(
          res.data.data,
          configGeneral,
          "A4",
          false,
          null,
        );
        setOpenBackDrop(false);
        setDataPdfFetched(newData);
      });
    } catch (error) {
      setOpenBackDrop(false);
      console.error(error);
    }
  };

  const handleOpenModalWhatsapp = (venta) => {
    setVentaSelected(venta);
    getDataPdf(venta);
  };

  const downloadExcelVentas = () => {
    let url = descargarExcelVentas;
    let fileName = `Excel_Ventas${dates.fromDate}_${dates.toDate}.xlsx`;
    setOpenBackDrop(true);

    if (ventas.length !== 0) {
      axios({
        url: url,
        params: {
          inicio: dates.fromDate,
          fin: dates.toDate,
          page: pagination.page,
          medioPago: filters.medioPago,
          tipoComprobante: filters.tipoComprobante,
          venta: debouncedSearchDescripcion,
          cliente: useClient.client ? useClient.client.idClienteDeSucursal : "",
          responsable: useEmpleado.empleado
            ? useEmpleado.empleado.idEmpleado
            : "",
          pendientesCae: pendienteCae ? 1 : 0,
          // paginated: paginated !== undefined ? paginated : 1,
          notaCredito: conNotaCredito === "all" ? "" : conNotaCredito,
          id_tarjeta: filters.tarjetaSelect || "",
          articulo: article ? article.repuesto_sucursal.idRepuestoSucursal : "",
          tipo_venta: useTipoVenta.tipoDeVenta
            ? useTipoVenta.tipoDeVenta.id
            : "",
          incluirTodasLasDevolucionesDePeriodo: Number(
            Boolean(incluirTodasLasDevolucionesDePeriodo),
          ),
          provincia: filters.provincia ? filters.provincia.id : null,
          transporte: filters.transporte ? filters.transporte.id : null,
        },
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Disposition": `attachment; filename=${fileName}`,
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/octet-stream" }),
          );
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.target = "_blank";
          link.click();
          setOpenBackDrop(false);
        })
        .catch((err) => {
          console.log(err);
          errorNotification(
            "No se ha podido descargar el Excel, intente nuevamente.",
          );
          setOpenBackDrop(false);
        });
    } else {
      errorNotification("No hay ventas para descargar, corrobore los filtros.");
      setOpenBackDrop(false);
    }
  };

  const downloadExcelVentasDeVentas = () => {
    let url = descargarExcelVentasDeVentas;
    let fileName = `Excel_Ventas${dates.fromDate}_${dates.toDate}.xlsx`;
    setOpenBackDrop(true);

    if (ventas.length !== 0) {
      axios({
        url: url,
        params: {
          inicio: dates.fromDate,
          fin: dates.toDate,
          page: pagination.page,
          medioPago: filters.medioPago,
          tipoComprobante: filters.tipoComprobante,
          venta: debouncedSearchDescripcion,
          cliente: useClient.client ? useClient.client.idClienteDeSucursal : "",
          responsable: useEmpleado.empleado
            ? useEmpleado.empleado.idEmpleado
            : "",
          pendientesCae: pendienteCae ? 1 : 0,
          // paginated: paginated !== undefined ? paginated : 1,
          notaCredito: conNotaCredito === "all" ? "" : conNotaCredito,
          id_tarjeta: filters.tarjetaSelect || "",
          articulo: article ? article.repuesto_sucursal.idRepuestoSucursal : "",
          tipo_venta: useTipoVenta.tipoDeVenta
            ? useTipoVenta.tipoDeVenta.id
            : "",
          provincia: filters.provincia ? filters.provincia.id : null,
          transporte: filters.transporte ? filters.transporte.id : null,
        },
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Disposition": `attachment; filename=${fileName}`,
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/octet-stream" }),
          );
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.target = "_blank";
          link.click();
          setOpenBackDrop(false);
        })
        .catch((err) => {
          console.log(err);
          errorNotification(
            "No se ha podido descargar el Excel, intente nuevamente.",
          );
          setOpenBackDrop(false);
        });
    } else {
      errorNotification("No hay ventas para descargar, corrobore los filtros.");
      setOpenBackDrop(false);
    }
  };
  const hasMultipleMedioPago = () => {
    const arrayPagosFiltrados =
      ventaAnular &&
      ventaAnular.pagos_cliente.filter((x) => x.tipo_movimiento === null);

    return arrayPagosFiltrados.length > 1;
  };

  const isCuentaCorriente =
    ventaAnular &&
    ventaAnular.pagos_cliente.some(
      (x) => x.medio_pago && x.medio_pago.nombre === "Cuenta Corriente",
    )
      ? true
      : false;

  const handleOpenModalAnular = () => {
    setModalAnular(true);
    setValueFcemA("N");
  };

  useEffect(() => {
    if (urlPdf) {
      setOpenModalWhatsapp(true);
    } else if (dataPdfFetched) {
      getPdfBlob("ventaA4", dataPdfFetched, configGeneral).then((res) => {
        if (res) {
          setUrlPdf(res.secure_url);
        }
      });
    }
  }, [urlPdf, dataPdfFetched]);

  useEffect(() => {
    ventaAnular &&
      ventaAnular.tipo_comprobante.nombre !== "FCEM A" &&
      setValueFcemA(null);
  }, [ventaAnular]);

  let columns = [
    <Checkbox
      style={{ color: "white" }}
      onClick={useSelection.handleSelectAll}
      checked={useSelection.isAll}
      disabled={!useSelection.existsInternalReceiptToSelect(ventas)}
    />,
    "Fecha",
    "Cliente",
    <div>
      TR{" "}
      <TooltipMoreInfoMaterial
        position={"right"}
        titleTooltip={<MessageTooltipTR />}
        color={"white"}
      />
    </div>,
    "N° Referencia",
    "Observación",
    "Monto",
    "--",
  ];
  console.log(configGeneral.esta_activa_clave_maestra);
  const getDataFacturaAgrupada = async (venta) => {
    if (selection.some((s) => s.id === venta.id)) {
      quitarSelectionCItoAgrupada(venta);
    }
    if (venta.is_agrupada) {
      setOpenBackDrop(true);
      try {
        const response = await request({
          method: "GET",
          url: getVentasInFactAgrupada(venta.id),
        });
        if (response.status === 200) {
          setVentasAgrupadas(response.data);
          setVentaAnular(venta);
          setOpenBackDrop(false);
        } else {
          setOpenBackDrop(false);
          errorNotification(
            "Error al obtener las ventas de la factura agrupada. Por favor reintente",
          );
        }
      } catch (error) {
        console.error(error);
        setOpenBackDrop(false);
        errorNotification(
          "Error al obtener las ventas de la factura agrupada. Por favor reintente",
        );
      }
    } else {
      setVentaAnular(venta);
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonFilter click={() => setCollapseOpen(!collapseOpen)} />
          <Grid container spacing={2} style={{ paddingTop: 8 }}>
            <Grid item xs={12}>
              <Collapse in={collapseOpen}>
                <Filtros
                  dates={dates}
                  period={period}
                  setDates={setDates}
                  setPeriod={setPeriod}
                  clear={clearState}
                  handleSearch={() => {
                    pagination.page !== 1
                      ? setPagination({ ...pagination, page: 1 })
                      : getVentas();
                  }}
                  mediosPago={mediosPago}
                  tiposComprobante={tiposComprobante}
                  filters={filters}
                  setFilters={setFilters}
                  loading={loading}
                  dataClient={{ useClient, useQuery }}
                  dataEmpleado={{ useEmpleado, useQueryEmpleado }}
                  dataNC={{ conNotaCredito, setConNotaCredito }}
                  dataTipoVenta={{ useTipoVenta, useQueryTipoVenta }}
                  dataArticle={{
                    article,
                    articulos,
                    loadingArticulos,
                    busquedaArticulo,
                    setBusqueda,
                    setArticle,
                  }}
                  dataFiltro={{
                    listfilters,
                    filter,
                    setFilter,
                  }}
                  checks={{
                    soloStock,
                    sinStock,
                    checkedArt,
                    tieneMeli,
                  }}
                  handleChangeFiltro={{
                    handleChangeArt,
                    handleChangeTodosMisArt,
                    handleChangeSoloStock,
                    handleChangeSinStock,
                    handleChangeConVinculacionMeli,
                  }}
                  tipoCliente={tipoCliente}
                  setTipoCliente={setTipoCliente}
                />
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={"mb-2 pb-4"}>
        {!isVendedor(perfil) && (
          <Grid item sm={3} lg={3} className="text-center">
            <Stats
              totales={totales}
              isLoading={loadingTotales}
              incluirTodasLasDevolucionesDePeriodo={
                incluirTodasLasDevolucionesDePeriodo
              }
              setIncluirTodasLasDevolucionesDePeriodo={
                setIncluirTodasLasDevolucionesDePeriodo
              }
              formatSecondColumn={(value) => {
                if (!value) {
                  return "$0,00";
                }

                const number = Number(value);
                if (isNaN(number)) {
                  return "Invalid number";
                }

                const numberFormatted = number.toLocaleString("es-AR", {
                  style: "currency",
                  currency: "ARS",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });

                return numberFormatted;
              }}
            />
          </Grid>
        )}
        <Grid
          item
          sm={!isVendedor(perfil) ? 9 : 12}
          lg={!isVendedor(perfil) ? 9 : 12}
          className="mt-2"
        >
          <ReportTable
            items={formatVentas(
              ventas,
              setComprobanteDetalle,
              handleOpenModalAnular,
              setVentaAnular,
              toggleModalValidate,
              toggleModalComprobanteAFactura,
              handleReCrearVenta,
              toggleModalDetalleAnular,
              generarDevolucion,
              generarPdf,
              Config_general(),
              setNewClient,
              abrirModalFacturarATerceros,
              handleSendMail,
              handleOpenModalSeleccionar,
              generarPdfConNotaCredito,
              handleOpenModalComprobante,
              handleOpenModalWhatsapp,
              handleModalFechaVencimiento,
              isVendedor,
              perfil,
              aplica_impuesto,
              selection,
              setSelection,
              getDataFacturaAgrupada,
              handleImprimirRotulo,
            )}
            columns={columns}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
          >
            <FilterHeaderTable
              search={search}
              handleSearch={handleSearch}
              dataCAE={{
                cantConCAE,
                pendienteCae,
                togglePendienteCAE,
                toggleModalValidateMasivo,
              }}
              descargarExcelVentas={downloadExcelVentas}
              selection={selection}
              setOpenModalFacturaAgrupada={setOpenModalFacturaAgrupada}
              descargarExcelVentasDeVentas={downloadExcelVentasDeVentas}
            />
          </ReportTable>
        </Grid>
      </Grid>
      {ventaSelected && modalDetalle ? (
        <ModalDetalle
          open={modalDetalle}
          selected={ventaSelected}
          toggle={() => toggleModalDetalle()}
          setVolverModal={setVolverModal}
          setModalSeleccionar={setModalSeleccionar}
          volverModal={volverModal}
        />
      ) : null}
      {ventaSelected && modalDetalleAnularComprobante ? (
        <ModalNotaCredito
          open={modalDetalleAnularComprobante}
          selected={ventaSelected}
          toggle={() => toggleModalDetalleAnular()}
          getVentas={getVentas}
          conComision={conComision}
          isInforme={true} //Informa que esta llamando al modal desde el informe de ventas
          setOpenBackdrop={setOpenBackDrop}
          setDataErrorAFIP={setDataErrorAFIP}
          closeModal={setModalDetalleAnularComprobante}
          modalPdf={modalPdf}
          setModalPdf={setModalPdf}
        />
      ) : null}

      {ventaAnular ? (
        ventaAnular.is_agrupada ? (
          <ModalAnulacionFactAgrupada
            open={modalAnular}
            handleClose={() => {
              setModalAnular(!modalAnular);
              setVentaAnular(null);
              setValueFcemA(null);
              setIdMedioPago(null);
            }}
            ventaSelected={ventaAnular}
            ventasAgrupadas={ventasAgrupadas}
            dataObservaciones={{ observaciones, setObservaciones }}
            getVentas={getVentas}
            setDataErrorAFIP={setDataErrorAFIP}
          />
        ) : (
          <ComponenteProtegido
            claveMaestraActiva={configGeneral.clave_maestra_activa}
            onClose={() => {
              setModalAnular(!modalAnular);
              setVentaAnular(null);
              setValueFcemA(null);
              setIdMedioPago(null);
            }}
          >
            <ModalConfirm
              open={modalAnular}
              handleClose={() => {
                setModalAnular(!modalAnular);
                setVentaAnular(null);
                setValueFcemA(null);
                setIdMedioPago(null);
              }}
              handleSubmit={() =>
                handleAnularVenta(
                  ventaAnular,
                  setVentaAnular,
                  setModalAnular,
                  getVentas,
                )
              }
              title={"Confirmar Anulación de Venta"}
              openBackdrop={openBackdropAnular}
              body={
                <ModalContentAnulacion
                  setIdMedioPago={setIdMedioPago}
                  ventaSelected={ventaAnular}
                  showMedioPago={
                    ventaAnular &&
                    ventaAnular.pagos_cliente.some(
                      (x) =>
                        x.medio_pago &&
                        x.medio_pago.nombre === "Cuenta Corriente",
                    )
                      ? false
                      : true
                  }
                  idMedioPago={idMedioPago}
                  cliente={ventaAnular.cliente}
                  valueFcemA={valueFcemA}
                  setValueFcemA={setValueFcemA}
                  dataObservaciones={{ observaciones, setObservaciones }}
                />
              }
              buttons={{ cancel: "CANCELAR", submit: "CONFIRMAR" }}
              disabled={
                disabledAnularVenta ||
                (isCuentaCorriente &&
                  !hasMultipleMedioPago() &&
                  !ventaAnular.cliente.cuenta_corriente) ||
                tieneChequesUtilizados(ventaAnular)
              }
            />
          </ComponenteProtegido>
        )
      ) : null}

      {ventaSelected && isOpenmodalFacturarATerceros && (
        <ModalFacturarClienteTercero
          open={isOpenmodalFacturarATerceros}
          ventaSelected={ventaSelected}
          cerrarModal={cerrarModalFacturarATerceros}
          montoMaximo={montoMaximo}
          setOpenModalFacturarATerceros={setOpenModalFacturarATerceros}
          setDataErrorAFIP={setDataErrorAFIP}
        />
      )}
      {ventaSelected && modalComprobanteAFactura && (
        <ModalComprobanteAFactura
          open={modalComprobanteAFactura}
          toggle={toggleModalComprobanteAFactura}
          updateVentas={updateVentas}
          comprobante={ventaSelected}
          setDataErrorAFIP={setDataErrorAFIP}
        />
      )}
      {modalGenerarCAE && (ventaSelected || ventasSelected) && (
        <ModalValidate
          open={modalGenerarCAE}
          toggle={() => setModalGenerarCAE(!modalGenerarCAE)}
          comprobante={ventaSelected}
          masivo={ventasSelected}
          update={getVentas}
          errorDetalle={modalErrorDetalle}
        />
      )}
      {openBackDrop && (
        <Backdrop
          className={classes.backdrop}
          open={openBackDrop}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {modalErrorCae && (
        <ModalVentasValidateCAEError
          open={modalErrorCae}
          toggle={() => {
            setModalErrorCae(!modalErrorCae);
            getVentas();
          }}
          ventas={ventasError}
        />
      )}
      {preview && (
        <Showmodal
          tipo={tipoPdf}
          preview={preview}
          toggle={toggle}
          data={datapdf}
          remito={isRemito}
          isFcemA={isFcemA}
        />
      )}
      {modalPdf.preview && (
        <Showmodal
          tipo={modalPdf.type}
          preview={modalPdf.preview}
          toggle={toggle}
          data={modalPdf.data}
          remito={isRemito}
        />
      )}
      {openModalEmail && (
        <ModalSendEmail
          open={openModalEmail}
          toggle={() => {
            setOpenModalEmail(false);
            setDataEmail(null);
          }}
          dataEmail={dataEmail}
          sendEmail={(newCorreoCliente, setLoadingEmail) =>
            sendEmail(newCorreoCliente, setLoadingEmail)
          }
          reporte={"Venta"}
        />
      )}
      {modalSeleccionar && (
        <ModalSeleccionarNc
          open={modalSeleccionar}
          handleClose={() => {
            setModalSeleccionar(false);
            // setRemito(false);
          }}
          venta={venta}
          generarPdf={generarPdf}
          setComprobanteDetalle={setComprobanteDetalle}
          setVolverModal={setVolverModal}
          generarPdfConNotaCredito={generarPdfConNotaCredito}
          generarPdfConNotaCreditoConPrevisualizacion={
            generarPdfConNotaCreditoConPrevisualizacion
          }
          isRemito={isRemito}
          setRemito={setRemito}
        />
      )}
      {modalComprobante && (
        <ModalImprimirComprobante
          open={modalComprobante}
          handleClose={() => {
            setModalComprobante(false);
          }}
          venta={ventaComprobante}
          generarPdf={generarPdf}
          generarPdfConPrevisualizacion={generarPdfConPrevisualizacion}
          isRemito={isRemito}
          setRemito={setRemito}
        />
      )}
      {openModalWhatsapp && (
        <ModalWhatsApp
          open={openModalWhatsapp}
          close={() => {
            setDataPdfFetched(null);
            setUrlPdf(null);
            setOpenModalWhatsapp(false);
            setVentaSelected(null);
          }}
          telefono={
            ventaSelected.cliente ? ventaSelected.cliente.telefonoContacto : ""
          }
          nombre={
            ventaSelected.cliente
              ? ventaSelected.cliente.razonSocial
              : "Consumidor final"
          }
          mensajePdf={`¡Hola${
            ventaSelected.cliente
              ? ` ${ventaSelected.cliente.razonSocial}!`
              : "!"
          } Te envíamos a continuación el comprobante de tu compra en formato PDF. Por favor Hace click en el siguiente link para visualizarlo: `}
          urlPdf={urlPdf}
        />
      )}
      {modalFechaVencimiento && (
        <ModalFechaVencimiento
          open={modalFechaVencimiento}
          close={() => setModalFechaVencimiento(false)}
          venta={venta}
          getVentas={getVentas}
        />
      )}
      {openModalFacturaAgrupada && (
        <ModalFacturacionAgrupada
          open={openModalFacturaAgrupada}
          toggle={() => {
            setOpenModalFacturaAgrupada(false);
            setSelection([]);
            getVentas();
          }}
          comprobantesSelected={selection}
          montoMaximo={montoMaximo}
          setDataErrorAFIP={setDataErrorAFIP}
          setOpenModalFacturaAgrupada={setOpenModalFacturaAgrupada}
        />
      )}
      {dataErrorAFIP &&
        dataErrorAFIP.open &&
        dataErrorAFIP.type === "factura" && (
          <ModalValidacionCAE
            open={dataErrorAFIP.open}
            handleClose={() => {
              getVentas();
              setDataErrorAFIP((prev) => ({
                ...prev,
                devoluciones: [],
                nroFactura: null,
                type: null,
                open: false,
              }));
              selection.length > 0 && setSelection([]);
            }}
            idVenta={dataErrorAFIP.venta}
            isNewVenta={false}
          />
        )}

      {dataErrorAFIP &&
        dataErrorAFIP.open &&
        dataErrorAFIP.type === "devoluciones" && (
          <ModalValidacionNotasCredito
            open={dataErrorAFIP.open}
            handleClose={() => {
              getVentas();
              setDataErrorAFIP((prev) => ({
                ...prev,
                devoluciones: [],
                nroFactura: null,
                type: null,
                open: false,
              }));
            }}
            notasCredito={dataErrorAFIP.devoluciones}
            idFactura={dataErrorAFIP.nroFactura}
          />
        )}

      {dataErrorAFIP &&
        dataErrorAFIP.open &&
        dataErrorAFIP.type === "notaCredito" && (
          <ModalValidacionGeneracionNC
            open={dataErrorAFIP.open}
            handleClose={() => {
              getVentas();
              setDataErrorAFIP((prev) => ({
                ...prev,
                devoluciones: [],
                nroFactura: null,
                type: null,
                open: false,
              }));
            }}
            notaCredito={dataErrorAFIP.devoluciones[0]}
            idFactura={dataErrorAFIP.nroFactura}
          />
        )}
    </>
  );
};

export default Ventas;
